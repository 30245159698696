/* roboto-300 - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: url('/assets/fonts/roboto-v30-latin-300.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/assets/fonts/roboto-v30-latin-300.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/assets/fonts/roboto-v30-latin-300.woff') format('woff'),
		/* Modern Browsers */
		url('/assets/fonts/roboto-v30-latin-300.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/assets/fonts/roboto-v30-latin-300.svg#Roboto') format('svg');
	/* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/fonts/roboto-v30-latin-regular.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/assets/fonts/roboto-v30-latin-regular.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/assets/fonts/roboto-v30-latin-regular.woff') format('woff'),
		/* Modern Browsers */
		url('/assets/fonts/roboto-v30-latin-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/assets/fonts/roboto-v30-latin-regular.svg#Roboto') format('svg');
	/* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url('/assets/fonts/roboto-v30-latin-500.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/assets/fonts/roboto-v30-latin-500.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/assets/fonts/roboto-v30-latin-500.woff') format('woff'),
		/* Modern Browsers */
		url('/assets/fonts/roboto-v30-latin-500.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/assets/fonts/roboto-v30-latin-500.svg#Roboto') format('svg');
	/* Legacy iOS */
}

table {
	width: 100%;

	&.table-fixed {
		table-layout: fixed;
	}
}

strong {
	font-weight: 500;
}

.hidden {
	display: none !important;
}

.uppercase {
	text-transform: uppercase;
}

.form-error {
	padding: 16px;
}

.na:empty:after {
	content: 'k. A.';
	color: rgba(0, 0, 0, 0.6);
	font-style: italic;
	font-size: 0.9em;
}

.bg-grey {
	background-color: #e0e0e0;
}

.txt-bold {
	font-weight: 500 !important;
}

.txt-italic {
	font-style: italic;
}

.font-18 {
	font-size: 18px;
}

.font-20 {
	font-size: 20px;
}

.no-margin {
	margin: 0 !important;
}

.pad-top-none {
	padding-top: 0 !important;
}

.pad-right-none {
	padding-right: 0 !important;
}

.pad-bottom-none {
	padding-bottom: 0 !important;
}

.pad-left-none {
	padding-left: 0 !important;
}

.nowrap {
	white-space: nowrap;
}

.pos-relative {
	position: relative !important;
}

.mat-card {
	&.card-margin {
		margin: 15px;
	}

	.mat-card-title {
		font-weight: 400;
	}
}

table {
	border-collapse: collapse;

	&.details-table {
		th {
			text-align: left;
			padding: 5px 16px 5px 0;
			font-size: 13px;
		}

		vd {
			padding: 5px 0;
			font-size: 13px;
		}
	}

	&.vd-data-table {
		&.mat-selectable {
			th {
				&.vd-data-table-column {
					&:first-child {
						width: 20px;
						padding: 0 16px 0 24px;
					}

					&:nth-child(2) {
						padding-left: 0;
					}
				}
			}

			vd {
				&.vd-data-table-cell {
					&:first-child {
						width: 20px;
						padding: 0 16px 0 24px;
					}

					&:nth-child(2) {
						padding-left: 0;
					}
				}
			}
		}

		.menu-column {
			padding: 0 12px 0 0;
			width: 1%;
		}

		.vd-data-table-row {
			cursor: default;
		}

		.vd-data-table-cell {
			&.mat-line {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&.multi-line-cell {
				padding-top: 16px;
				padding-bottom: 16px;
			}

			&.dense-cell {
				font-size: 12px;
				letter-spacing: 0.020em;
			}

			&.menu-cell {
				padding: 0 12px 0 0;
				width: 1%;
			}

			.mat-select-value-text {
				font-size: 12px;
			}
		}

		.avatar-row {
			padding-right: 0;
			width: 30px;
		}

		.search-row {
			height: 34px;

			&:hover {
				background-color: initial !important;
			}

			.vd-data-table-cell {
				padding-right: 0;
			}
		}

		md-select {
			&.table-filter {
				vertical-align: top;
				border: 1px solid #ccc;
				padding: 1px 2px 0;
				width: 100%;
				background-color: transparent;

				.mat-select-trigger {
					height: 11px;
					min-width: 64px;
					font-size: 12px;

					.mat-select-underline {
						display: none;
					}
				}
			}
		}
	}

	&.labeled {
		tr:not(.raw) {
			td {
				padding-left: 0;
				padding: 3px 0;

				&:first-child {
					font-weight: 500;
					padding-right: 6px;
					white-space: nowrap;

					@media (min-width: 801px) {
						width: 1%;
					}

					&:after {
						content: ':';
						padding-right: 4px;
					}
				}

				&:empty:after {
					content: 'N/A';
					color: rgba(0, 0, 0, 0.6);
					font-style: italic;
					font-size: 0.9em;
				}
			}
		}
	}
}

.sub-panel-header {
	background-color: #afca0b1a !important;
}

/* data-table BEGIN ++++++++++++++++++++++++++++++++++++++++++++++++++++ */
.mat-table {
	overflow: auto;
	width: 100%;
	border-collapse: separate;

	.mat-header-row {
		.mat-header-cell {
			padding-right: 16px;

			.mat-form-field {
				width: 100%;

				&.mat-form-field-appearance-outline {
					.mat-form-field-wrapper {
						padding-bottom: 0;
					}

					.mat-form-field-infix {
						padding: 0.7em 0 0.7em 0;
					}

					.mat-form-field-flex {
						padding: 0 .45em 0 .45em;
					}
				}
			}
		}
	}

	.mat-row {
		cursor: default;

		&:hover {
			background: #f5f5f5;
		}

		&:active {
			background: #eaeaea;
		}

		.mat-cell {
			text-overflow: ellipsis;
			overflow: hidden;
			padding-right: 16px;

			mat-icon {
				&.mat-list-avatar {
					background-color: rgba(0, 0, 0, 0.09);
					align-items: center;
					align-content: center;
					justify-content: center;
					display: flex;
					flex-shrink: 0;
					width: 32px !important;
					height: 32px !important;
					border-radius: 50%;
				}
			}

			.mat-form-field {
				flex: 1;
				display: flex;
				align-items: center;

				.mat-form-field-wrapper {
					padding-bottom: 0;

					.mat-form-field-infix {
						padding: 2px 0 8px 0;
					}
				}
			}

			>span:empty:after {
				content: 'k. A.';
				color: rgba(0, 0, 0, 0.6);
				font-style: italic;
				font-size: 0.9em;
			}

			&.succeeded {
				border-left: 3px solid rgb(0, 167, 64);
			}

			&.warn {
				border-left: 3px solid rgb(253, 165, 0);
			}

			&.error {
				border-left: 3px solid rgba(255, 0, 0, 0.637);
			}

			&.cancelled {
				border-left: 3px solid rgb(44, 44, 44);
			}
		}

		&.succeeded {
			.mat-cell:first-child {
				border-left: 3px solid rgb(0, 167, 64);
			}
		}

		&.warn {
			.mat-cell:first-child {
				border-left: 3px solid rgb(253, 165, 0);
			}
		}

		&.error {
			.mat-cell:first-child {
				border-left: 3px solid rgba(255, 0, 0, 0.637);
			}
		}

		&.cancelled {
			.mat-cell:first-child {
				border-left: 3px solid rgb(44, 44, 44);
			}
		}
	}

	.mat-header-row,
	.mat-row {

		.cdk-column-select,
		.cdk-column-filter-select {
			width: 20px;
			max-width: 20px;
			padding-right: 12px;
			margin-right: 0px;
			overflow: initial;
		}

		.cdk-column-id,
		.cdk-column-filter-id {
			width: 55px;
			max-width: 55px;
			text-align: right;
			justify-content: flex-end;

			.vd-table-filter {
				margin-right: 0px;
			}
		}

		.cdk-column-filter-date,
		.cdk-column-filter-createdDate,
		.cdk-column-filter-updatedDate,
		.cdk-column-date,
		.cdk-column-createdDate,
		.cdk-column-updatedDate {
			width: 76px;
			max-width: 76px;
			justify-content: flex-end;
			text-align: right;
		}

		.cdk-column-filter-action,
		.cdk-column-action {
			width: 42px;
			max-width: 42px;
			margin-right: 8px;
			padding-right: 0;
		}
	}

	.cdk-column-emptyResult {
		font-style: italic;
		color: #8c8c8c;
	}

	.mat-cell,
	.mat-header-cell {
		&:first-of-type {
			padding-left: 16px;
		}

		&.right {
			display: flex;
			justify-content: flex-end;
			text-align: right;
		}

		.mat-sort-header-content {
			text-align: left;
		}
	}

	&[multitemplatedatarows] {
		.mat-row {
			&.expanded-row {
				.mat-cell {
					border-bottom-width: 0;
					background-color: #f6f6f6;
				}

				+.detail-row {
					background-color: #f6f6f6;
					display: table-row;

					.mat-cell {
						padding-top: 8px;
						padding-bottom: 8px;
					}
				}
			}

			&:not(.expanded-row) {
				+.detail-row {
					display: none;

					.mat-cell {
						border-bottom-width: 0;
					}
				}
			}

			.element-detail {
				overflow: hidden;
				display: flex;
			}
		}
	}
}

.table-empty {
	min-height: 48px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding-left: 24px;

	.mat-cell {
		font-style: italic;
		color: #8c8c8c;
	}
}

.mat-paginator-container {
	.mat-form-field-appearance-outline {
		margin: 4px 0 0 4px;

		.mat-form-field-wrapper {
			padding-bottom: 0;
		}

		.mat-form-field-infix {
			padding: 0.7em 0 0.7em 0;
		}

		.mat-form-field-flex {
			padding: 0 .45em 0 .45em;
		}
	}
}

.loading-progress {
	height: 3px;
	background: white;

	.mat-progress-bar {
		height: 3px;
	}
}

.vd-layout-manage-list-main {
	>.mat-toolbar {
		padding-right: 10px;
	}
}

vd-layout-footer-inner {
	.mat-paginator {
		background-color: transparent;
		min-height: 48px;

		.mat-paginator-page-size {
			@media (max-width: 599px) {
				display: none;
			}
		}
	}

	&.pagination {
		&.vd-layout-footer {
			padding: 0;
		}

		.mat-fab-bottom-left {
			bottom: 28px;

			&.mat-mini-fab {
				bottom: 36px;
				left: 22px;
			}
		}
	}
}

/* data-table END ++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* Sidenav BEGIN +++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
mat-sidenav {
	.mat-toolbar {
		padding-right: 0;
		font-size: 1.1em;
	}

	.mat-drawer-inner-container {
		display: flex;
		flex-direction: column;
	}
}

mat-sidenav-content {
	.mat-toolbar {
		font-size: 1.1em;
	}
}

/* Sidenav END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.file-input {
	padding-top: 8px;

	.mat-input-wrapper {
		margin: 8px;
		padding-bottom: 22px;
	}

	button {
		&.vd-file-input {
			display: none;
		}
	}
}

/* Layout +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
.loading {
	&::before {
		content: "Bitte warten...";
		font-size: 30px;
		font-weight: 500;
		width: 100%;
		text-align: center;
		color: white;
		position: absolute;
		z-index: 99999;
		top: 50%;
		text-shadow: -1px 3px 5px #505050;
	}

	&::after {
		content: ' ';
		background: #00000052;
		position: absolute;
		z-index: 2000;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}

mat-toolbar {
	[mat-icon-button] {
		&.vd-layout-menu-button {
			margin-right: 0px;
		}
	}
}

.vd-navigation-drawer-name {
	overflow: hidden;
	text-overflow: ellipsis;
	// cursor: pointer;
}

.vd-navigation-drawer-label {
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}

.main-container {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	.main-toolbar {
		z-index: 2;

		.mat-icon-button {
			margin-left: 0;
		}
	}

	.main-sidenav.mat-drawer-side,
	.mat-drawer-container {
		background-color: #e8e8e8;
		border-right: none;
	}

	.main-sidenav {
		max-width: 290px;
		min-width: 290px;
		display: flex;

		.mat-drawer-inner-container {
			display: flex;
			flex-direction: column;
		}

		.mat-nav-list {
			padding-top: 16px;

			a {
				font-size: 14px;
			}
		}

		.mat-list-item-content {
			mat-icon {
				margin-left: 4px;
				margin-right: 18px;
				color: #737373;
				fill: #737373;
			}
		}

		.main-footer {
			font-size: 13px;
			margin: 12px 15px 12px 24px;
			color: #909090;
		}
	}

	.app-name {
		margin-left: 8px;
	}

	.sidenav-container {
		flex: 1;
	}

	.content-center {
		margin: 0 auto;
	}

	&.mobile {
		.main-toolbar {
			position: fixed;
			z-index: 2;
		}

		.sidenav-container {
			flex: 1 0 auto;
		}
	}
}

.vd-layout-center-wrapper {
	margin-top: 18px;
	margin-left: 0;
	margin-right: 0;
	padding: 0 8px;
	width: 100%;
	min-height: 100%;
	height: 100%;

	@media (min-width: 600px) {
		flex-direction: row;
		box-sizing: border-box;
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: center;

		.vd-layout-center {
			max-width: 70%;
			flex: 1 1 70%;
			-webkit-box-flex: 1;
			max-height: 100%;
			box-sizing: border-box;
		}
	}

	@media (max-width: 599px) {
		.vd-layout-center {
			max-width: 100% !important;
		}
	}
}

vd-layout-card-over {
	&.accordion {
		>.vd-layout-card-over-wrapper {
			>.vd-layout-card-over {
				>.mat-card {
					background-color: transparent;
					box-shadow: none;
					margin-bottom: 100px;
				}
			}
		}
	}

	mat-expansion-panel {
		.mat-expansion-panel-header {
			font-size: 1.1em;
		}
	}
}

.vd-layout-card-over-wrapper {
	>.vd-layout-card-over {
		max-width: 1000px !important;
	}
}

.mat-icon {
	&.mat-icon-logo {
		height: 24px;
		width: 10px !important;
	}
}

.sub-card {
	background: #e0e0e0;

	::ng-deep .ql-container {
		max-height: 100px;

		.ql-editor {
			min-height: 100px;
			max-height: 100px;
			padding-bottom: 1rem;
		}
	}

	.mat-card-content {
		padding: 8px;
	}
}

mat-expansion-panel {
	.mat-expansion-panel-header {
		.mat-expansion-panel-header-title {
			align-items: center;
		}

		&[aria-disabled="true"] {
			color: rgba(0, 0, 0, 0.87) !important;
			padding-right: 0;

			.mat-expansion-panel-header-description {
				color: rgba(0, 0, 0, 0.54) !important;
			}
		}
	}

	.mat-expansion-panel-body {
		padding: 0 8px 16px;
	}

	.mat-action-row {
		padding: 8px 8px 8px 8px;

		&.pagination {
			padding: 0;
		}
	}

	&.wrap {
		>.mat-expansion-panel-content {
			>.mat-expansion-panel-body {
				background: #e0e0e0;
				padding: 8px;

				.panel-content {
					background: white;
				}

				.mat-card {
					margin-left: 0;
					margin-right: 0;

					.mat-card-title {
						font-size: 20px;
					}
				}
			}
		}
	}

	&.flat,
	&.no-margin {
		>.mat-expansion-panel-content {
			>.mat-expansion-panel-body {
				background: #e0e0e0;
				padding: 0;
			}
		}
	}

	&.pad-none {
		>.mat-expansion-panel-content {
			>.mat-expansion-panel-body {
				padding: 0;
			}
		}
	}
}

.mat-step {
	font-size: 0.87rem;

	.sub-card {
		.mat-card {
			.mat-card-title {
				font-size: 20px;
			}
		}
	}

	.mat-step-header {
		position: relative;

		.mat-button {
			position: absolute;
			right: 16px;
			top: 16px;
		}
	}
}

/* Layout END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* Timeline ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
.timeline {
	border-left: 6px solid #1bbbe4;
	margin-left: 10px;
	letter-spacing: 0.5px;
	position: relative;
	line-height: 1.4em;
	padding: 20px;
	list-style: none;
	text-align: left;

	.event {
		border-bottom: 1px solid rgba(160, 160, 160, 0.2);
		padding-bottom: 15px;
		margin-bottom: 20px;
		position: relative;

		.mat-subheading-2 {
			margin: 0 0 0px;
		}

		&:last-of-type {
			padding-bottom: 0;
			margin-bottom: 0;
			border: none;
		}

		&:before,
		&:after {
			position: absolute;
			display: block;
			top: 0;
		}

		&:before {
			left: -177.5px;
			color: #212121;
			content: attr(data-date);
			text-align: right;
			font-size: 16px;
			min-width: 120px;
		}

		&:after {
			box-shadow: 0 0 0 6px #1bbbe4;
			left: -29px;
			background: #ffffff;
			border-radius: 50%;
			height: 11px;
			width: 11px;
			content: "";
			top: 5px;
		}

		&:before {
			left: 0.5px;
			top: 20px;
			min-width: 0;
			font-size: 13px;
		}
	}
}

/* Timeline END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* Dialog ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
.mat-dialog-container {
	font-size: 0.9em;
	padding: 24px 16px !important;
}

.dialog-full-screen {
	@media (max-width: 599px) {
		max-width: 100% !important;
		height: 100%;

		.mat-dialog-container {
			max-width: 100vw;
			max-height: 100vh;
			height: 100%;
			width: 100%;

			.mat-dialog-content {
				max-height: 100% !important;
			}
		}
	}
}

/* Dialog END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* Fixes +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
mat-form-field {
	min-width: auto !important;

	.mat-form-field-infix {
		width: auto;
	}

	&.mat-form-field-appearance-outline {
		&.mat-form-field-type-mat-chip-list {
			.mat-chip-list {
				&.flat {
					.mat-chip {
						background: none;
						font-weight: 400;
						min-height: initial;
						padding: 7px 0px;

						&:hover {
							&::after {
								opacity: 0 !important;
							}
						}

						&.mat-chip-disabled {
							opacity: 0.8;
						}
					}
				}
			}
		}
	}

	&.mat-form-field-type-tinymce-editor {
		.mat-form-field-infix {
			padding: 1em 0 0 0;
		}

		.mat-form-field-flex {
			padding: 0;
		}

		.mat-form-field-outline {
			color: transparent;
		}

		.mat-form-field-label {
			font-size: 1.3em;
		}

		.mat-form-field-subscript-wrapper {
			padding: 0;
		}

		&.mat-form-field-invalid {
			&.mat-form-field-invalid {
				.mat-form-field-outline-thick {
					color: transparent;
				}
			}

			.tox.tox-tinymce {
				border: 2px solid #b71c1c;
			}
		}
	}

	&.mat-form-field-disabled {

		vd-chips,
		vd-select,
		mat-chip-list {
			&[required] {
				+.mat-form-field-label-wrapper {
					mat-label {
						&::after {
							content: ' *';
							position: relative;
						}
					}

					.mat-placeholder-required {
						display: none;
					}
				}
			}
		}
	}
}

mat-card {

	.mat-card-actions,
	.mat-card-content,
	.mat-card-subtitle,
	.mat-card-title {
		display: block;
		margin-bottom: 16px;
	}

	.mat-card-title {
		font-size: 24px;
		font-weight: 400;
	}
}

mat-toolbar {
	&[dense] {
		height: 50px;
	}
}

.mat-select-disabled {
	.mat-select-value {
		color: rgba(0, 0, 0, 0.64);
	}

	.mat-select-arrow {
		opacity: 0;
	}
}

.chip-option {
	>* {
		line-height: initial;
	}
}

.mat-tooltip {
	font-size: 0.7em;
}

/* Fixes END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.notification-menu {
	.mat-menu-item {
		height: initial;
		white-space: initial;
		line-height: initial;
		padding: 10px 16px;

		p {
			padding: 0;
			margin: 0;
		}

		&:not(:last-child) {
			border-bottom: 1;
			border-bottom-color: rgba(0, 0, 0, 0.12);
		}
	}

	&.mat-menu-panel {
		@media (min-width: 460px) {
			min-width: 380px;
		}
	}
}

.cdk-overlay-pane {
	div.mat-menu-panel {
		max-width: none;
	}
}