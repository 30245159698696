/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '~@angular/material/theming';
@import './node_modules/@messaia/cdk/lib/common/theming/all-theme';

/* Custom colors */
$mat-cyan2: (
	50: #effcff,
	100: #b2ebf2,
	200: #80deea,
	300: #4dd0e1,
	400: #26c6da,
	500: #00bcd4,
	600: #1bbbe4,
	700: #0097a7,
	800: #00838f,
	900: #006064,
	A100: #84ffff,
	A200: #18ffff,
	A400: #00e5ff,
	A700: #00b8d4,
	contrast: (
		50: $dark-primary-text,
		100: $dark-primary-text,
		200: $dark-primary-text,
		300: $dark-primary-text,
		400: $dark-primary-text,
		500: $light-primary-text,
		600: $light-primary-text,
		700: $light-primary-text,
		800: $light-primary-text,
		900: $light-primary-text,
		A100: $dark-primary-text,
		A200: $dark-primary-text,
		A400: $dark-primary-text,
		A700: $dark-primary-text,
	)
);

$mat-lime: (
    50 : #f5f9e2,
    100 : #e7efb6,
    200 : #d7e585,
    300 : #c7da54,
    400 : #bbd230,
    500 : #afca0b,
    600 : #a8c50a,
    700 : #9fbd08,
    800 : #96b706,
    900 : #86ab03,
    A100 : #f4ffd4,
    A200 : #e8ffa1,
    A400 : #dbff6e,
    A700 : #d4ff55,
    contrast: (
		50: $dark-primary-text,
		100: $dark-primary-text,
		200: $dark-primary-text,
		300: $dark-primary-text,
		400: $dark-primary-text,
		500: $light-primary-text,
		600: $light-primary-text,
		700: $light-primary-text,
		800: $light-primary-text,
		900: $light-primary-text,
		A100: $dark-primary-text,
		A200: $dark-primary-text,
		A400: $dark-primary-text,
		A700: $dark-primary-text,
    )
);


/*
Include the base styles for Angular Material core. We include this here so that you only
have to load a single css file for Angular Material in your app.
*/
@include mat.core();

/*
Define the palettes for your theme using the Material Design palettes 
available in palette.scss  (imported above). 
For each palette, you can optionally specify a default, lighter, and darker hue.
*/
$primary: mat.define-palette($mat-cyan2, 600);
$accent: mat.define-palette($mat-lime, 500, A100, A400);

/* The warn palette is optional (defaults to red) */
$warn: mat.define-palette($mat-red, 900);

/* Create the theme object (a Sass map containing all of the palettes) */
$theme: mat.define-light-theme($primary, $accent, $warn);

/*
Include theme styles for core and each component used in your app.
Alternatively, you can import and @include the theme mixins for each component
that you are using.
*/
@include mat.all-component-themes($theme);
@include vd-theme($theme);

mat-nav-list {
    [mat-list-item].active {
		.mat-list-item-content {
			background: rgba(0,0,0,.04);
		}
        mat-icon[mat-list-avatar] {
            background-color: mat-color($accent);
            color: mat-color($accent, default-contrast)
        }
        mat-icon[mat-list-icon] {
            color: mat-color($accent);
        }
    }
}

.form-error {
	background-color: #eeeeee;
	color: mat-color($warn);
}

snack-bar-container {
    &.error {
        background-color: mat-color($warn);
        white-space: pre-line;
        .mat-simple-snackbar-action {
            color: white;
        }
    }
    &.warn {
        background-color: mat-color($accent);
        .mat-simple-snackbar-action {
            color: white;
        }
    }
    &.primary {
        background-color: mat-color($primary);
        .mat-simple-snackbar-action {
            color: white;
        }
    }
}